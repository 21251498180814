<template>
    <nav class="navbar navbar-expand bg-white text-dark navbar-light fixed-top shadow-sm px-4 py-1"
         :class="{ 'navbar-searching': searching }">
        <button class="navbar-toggler d-block d-md-none"
                type="button"
                aria-controls="top-navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="$store.commit('sidebar/openSidebar', '100%')">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="top-navigation" class="collapse navbar-collapse">
            <div class="w-100"></div>
            <!--
            <form class="d-flex w-100 align-items-center" @submit="onSearch">
                <font-awesome-icon class="d-inline-block" icon="search"/>
                <label class="sr-only" for="navbar-search">{{ $tc('search') }}</label>
                <input id="navbar-search"
                       class="form-control border-0 shadow-none"
                       type="search"
                       :placeholder="$tc('search') + '...'"
                       :aria-label="$tc('search')"
                       @focus="searching = true"
                       @blur="searching = false"
                       v-model="term">
            </form>
            -->

            <ul class="navbar-nav ml-2">
                <li class="nav-item dropdown ml-2" v-if="user !== null">
                    <div class="nav-link dropdown-toggle p-0 d-inline-flex align-items-center h-100"
                       data-toggle="dropdown"
                       role="button"
                       aria-haspopup="true"
                       aria-expanded="false">
                        <v-gravatar id="user-avatar" class="mr-2" :email="user.email" :size="24"/>
                        <span class="font-weight-bold text-dark d-none d-md-block">{{ user.firstName }} {{ user.lastName }}</span>
                    </div>
                    <div class="dropdown-menu position-right">
                        <router-link class="dropdown-item" :to="{ name: 'profile' }">{{ $tc('profile') }}</router-link>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" @click="onSignOut">{{ $tc('sign-out') }}</button>
                    </div>
                </li>
                <li class="nav-item dropdown ml-2">
                    <a class="nav-link dropdown-toggle text-dark position-relative"
                       data-toggle="dropdown"
                       role="button"
                       aria-haspopup="true"
                       aria-expanded="false">
                        <font-awesome-icon :icon="['fas', 'language']" size="lg"/>
                    </a>
                    <div class="dropdown-menu position-right">
                        <button class="dropdown-item" @click="$i18n.locale = 'de-DE'">Deutsch</button>
                        <button class="dropdown-item" @click="$i18n.locale = 'en-US'">English (US)</button>
                    </div>
                </li>
                <li class="nav-item ml-2">
                    <a class="nav-link text-dark position-relative" role="button">
                        <font-awesome-icon :icon="['far', 'bell']" size="lg"/>
                    </a>
                </li>
                <li class="nav-item ml-2">
                    <a class="nav-link text-dark position-relative" role="button">
                        <font-awesome-icon :icon="['far', 'question-circle']" size="lg"/>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import 'bootstrap/js/src/dropdown';

export default {
    name: "ComponentNavbar",
    data() {
        return {
            searching: false,
            term: ''
        }
    },
    computed: {
        appTitle() {
            return process.env.VUE_APP_TITLE;
        },
        appVersion() {
            return process.env.VUE_APP_VERSION;
        },
        user() {
            return this.$store.state.forkLane.user;
        }
    },
    methods: {
        onSearch() {
            console.log(this.term);
        },
        onSignOut() {
            this.$store.dispatch('forkLane/logout');
        }
    },
    beforeMount() {
        this.version = process.env.VUE_APP_VERSION;
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";


nav {
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom-color .3s;
    -moz-transition: border-bottom-color .3s;
    -ms-transition: border-bottom-color .3s;
    -o-transition: border-bottom-color .3s;
    transition: border-bottom-color .3s;

    &.navbar-searching {
        border-bottom-color: $primary;
    }
}

.position-right {
    left: unset;
    right: 0;
}

</style>