<template>
    <div class="border-top w-100 text-center">
        <a href="https://www.forklane.com"
          class="text-dark px-2"
          target="_blank">forklane.com</a>
        <a href="#" class="text-dark px-2">Cookies</a>
    </div>
</template>

<script>
export default {
    name: "ComponentFooter"
}
</script>

<style scoped lang="scss">

</style>