<template>
    <nav>
        <div class='collapse-sidebar-horizontally'>
            <div class="collapse-sidebar-inner" @click="$store.commit('sidebar/toggleSidebar')">
                <font-awesome-icon :icon="sidebarCollapsed ? 'chevron-left' : 'chevron-right'"
                                   size="lg"
                                   class="collapse-sidebar-arrow"/>
            </div>
        </div>
        <div id="icon-container" class="d-flex justify-content-between">
            <router-link to="/">
                <img class="d-block mw-100"
                     :class="{'p-3' : $store.state.sidebar.width != '0px'}"
                     :src="sidebarCollapsed ? require('@/assets/img/fork-lane.png') : require('@/assets/img/forklane-favicon.png')"
                     :alt="appTitle">
            </router-link>
            <font-awesome-icon icon="times"
                               size="lg"
                               class="text-white m-2 d-block d-md-none cursor-pointer"
                               @click="$store.commit('sidebar/closeSidebar', '0px')"/>
        </div>

        <ul class="nav flex-column">
            <template v-for="(content, index) in contents">
                <li class="sidebar-heading nav-item" :key="index">
                    <a class="btn d-block nav-item rounded-0"
                    :class="getSidebarHeadingClasses()"
                    role="button"
                    :title="content.name"
                    :aria-expanded="!content.collapsed"
                    @click="$store.commit('sidebar/toggleGroup', index)">
                        <span class="group-label" :class="{ hide: !sidebarCollapsed }">
                            {{ content.name }}
                        </span>
                        <font-awesome-icon :icon="content.collapsed ? 'chevron-down' : 'chevron-up'"
                                        :pull="sidebarCollapsed ? 'right' : undefined"
                                        :size="sidebarCollapsed ? '1x' : 'lg'"/>
                    </a>
                </li>
                <ul class="sidebar-submenu nav flex-column collapse"
                    :class="{ show: !content.collapsed }"
                    v-for="(item, i) in content.items" :key="index + '-' + i">
                    <li class="nav-item w-100" :title="item.name">
                        <router-link class="nav-link text-left" active-class="active" exact :to="{ name: item.route }">
                            <font-awesome-icon :icon="item.icon" class="mr-2"/>
                            <span class="group-label" :class="{ hide: !sidebarCollapsed }">
                                {{ item.name }}
                            </span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "ComponentSidebar",
    computed: {
        sidebarCollapsed() {
            return this.$store.state.sidebar.open;
        },
        appTitle() {
            return process.env.VUE_APP_TITLE;
        },
        contents() {
            return this.$store.state.sidebar.content;
        }
    },
    methods: {
        getSidebarHeadingClasses() {
            return {
                'text-left': true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

#sidebar nav > ul {
    overflow-y: scroll;
    height: calc(100vh - 97px);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

nav {
    #icon-container {
        position: relative;
    }

    .cursor-pointer {
        cursor: pointer;
    }
    
    .collapse-sidebar-horizontally {
        height: 100%;
        width: 15px;
        position: absolute;
        right: -15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .collapse-sidebar-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 15px;
            background-color: rgba(194, 195, 191, .5);
            transition: height .3s ease;

            &:hover {
                height: 100vh;
                transition: height .3s ease;
                cursor: pointer;
            }
        }
    }

    .nav {
        flex-wrap: nowrap;
    }

    .sidebar-heading a {
        color: darken($white, 30%);
        //color: $primary;
        background-color: darken($dark, 5%);
    }

    .sidebar-heading .group-label.hide {
        display: none;
    }

    .sidebar-submenu .group-label.hide {
        display: none;
    }

    .sidebar-submenu a {
        color: $white;

        &:hover {
            background-color: $primary;
            color: $white;

            .fa-circle {
                color: $white;
            }
        }

        &.active {
            background-color: lighten($dark, 10%);
            color: $white;
        }

        .fa-circle {
            color: $primary;
        }
    }
}

@media only screen and (max-width: 768px) {
    .collapse-sidebar-horizontally {
        display: none !important;
    }
}
</style>