<template>
    <div>
        <component-navbar id="navbar" :style="{ left: sidebarWidth }"/>

        <div id="sidebar"
             class="shadow-sm bg-dark"
             :class="{ collapsed: !sidebarOpen }"
             :style="{ width: sidebarWidth}">
            <component-sidebar/>
        </div>

        <main id="content"
              class="bg-white"
              :style="{ width: 'calc(100% - ' + sidebarWidth + ')',
                        marginLeft: sidebarWidth === '100%' ? 0 : sidebarWidth}">
            <div v-if="title" class="bg-light border-bottom px-4 py-2 shadow">
                <h1 class="m-0 h2">{{ title }}</h1>
            </div>

            <div id="page-content" class="bg-white grid text-left px-4 py-3">
                <slot/>
            </div>
            <component-toast-container/>
            <component-footer id="footer"/>
        </main>
    </div>
</template>

<script>
    import ComponentNavbar from "@/components/ui/ComponentNavbar";
    import ComponentSidebar from "@/components/ui/ComponentSidebar";
    import ComponentFooter from "@/components/ui/ComponentFooter";
    import ComponentToastContainer from "@/components/ui/toast/ComponentToastContainer";

    export default {
        name: "LayoutFullWidth",
        components: { ComponentSidebar, ComponentNavbar, ComponentFooter, ComponentToastContainer },
        computed: {
            sidebarWidth() {
                return this.$store.state.sidebar.width;
            },
            sidebarOpen() {
                return this.$store.state.sidebar.open;
            }
        },
        props: {
            title: {
                type: [Boolean, String],
                required: false,
                default: false
            }
        },
        data() {
            return {
                user: null
            }
        },
        created() {
            if (window.innerWidth < 768) {
                this.$store.commit('sidebar/closeSidebar', '0px')
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/variables";

    #navbar {
        z-index: 99;
        transition: left .3s ease;
    }

    #sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        transition: width .3s ease;

        &.collapsed {
            transition: width .3s ease;
        }
    }

    #content {
        margin-top: 47px;
        height: calc(100vh - 47px);
        transition: all .3s ease;
        display: flex;
        flex-direction: column;

        #page-content {
            flex: 1 0 auto;
        }

        #footer {
            background-color: #e9ecef;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            padding: .75em;
        }
    }

    @media only screen and (max-width: 768px) {
        #sidebar {
            transition: width .6s ease;

            &.collapsed {
                transition: width .6s ease;
            }
        }
    }
</style>